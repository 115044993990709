<template>
  <section>
    <NavBody>
      <template #body>
        <p class="font-bold text-2xl text-brand-primary">See Available Jobs</p>
        <p class="font-normal text-base text-[#8C94A6]">Have a beautiful {{ greeting }}!</p>
      </template>
    </NavBody>
    <div class="fixed inset-x-0 top-[225px] mx-auto lg:max-w-[80rem] z-20 lg:h-[725px] h-full gap-6 w-full px-4 lg:px-0 bg-[#FAFAFA]">
      <div class="overflow-scroll h-full scrollbar-hide">
        <form class="flex items-center justify-between mb-6 mt-2 bg-white px-6 py-4 gap-x-40">
          <div class="grid gap-4 md:flex grow">
            <div class="relative md:w-1/4">
              <Select place-holder="Date Posted" :options="enums.job_types" v-model="filter.date_posted" />
            </div>
            <div class="relative md:w-1/4">
              <Select place-holder="Job Type" :options="enums.job_types" v-model="filter.job_type" />
            </div>
            <div class="relative md:w-1/4">
              <Select place-holder="Experience Level" :options="enums.job_types" v-model="filter.expereince" />
            </div>
            <div class="relative md:w-1/4">
              <Select place-holder="Work type" :options="enums.job_work_types" v-model="filter.work_type" />
            </div>
          </div>
          <Button data-test="filter-jobs-button" label="All Filter" color="white" size="4px" class="bg-white border border-[#1C1C1E1F] rounded-lg" @click="getJobs" />
        </form>
        <div class="grid lg:grid-cols-3 gap-4 mb-10">
          <Card data-test="" :details="allJobs" v-if="pageLoaded" />
          <Loading :is-loading="!pageLoaded" className="flex justify-center" />
          <!-- <Paginate
          data-test="paginate"
          @next="
            currentPage += 1;
            getJobs();
          "
          @prev="
            currentPage > 1 ? (currentPage -= 1) : currentPage;
            getJobs();
          "
        /> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import Search from '@/assets/icons/search.svg?inline';
import Button from '@/components/Button.vue';
import Select from '@/components/form/Select.vue';
import Input from '@/components/form/Input.vue';
import Card from '@/components/jobCard.vue';
import NavBody from '@/components/NavBody.vue';
import Paginate from '@/components/Paginate.vue';
import { ref, computed, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { errorMessage, alert } from '@/utils/helper';
import Loading from '@/components/Loading.vue';

const store = useStore();
const router = useRouter();

const pageLoaded = ref(false);
const currentPage = ref(1);

const filter = ref({
  search: '',
  type: '',
  work_type: '',
});

const JobsData = computed(() => {
  return store.getters['global/getAllJobs'];
});

const allJobs = computed(() => {
  return store.getters['global/getJobs'];
});

const greeting = computed(() => {
  const currentHour = new Date().getHours();

  if (currentHour < 12) {
    return 'Morning';
  } else if (currentHour < 18) {
    return 'Afternoon';
  } else {
    return 'Evening';
  }
});

const userDetails = computed(() => store.getters['auth/userDetails']);

const getJobs = async () => {
  pageLoaded.value = false;
  try {
    await store.dispatch('global/getAllJobs', { page: currentPage.value, ...filter.value });
    pageLoaded.value = true;
  } catch (error) {
    alert(errorMessage(error), 'error');
    pageLoaded.value = true;
    if (error.response.status === 409) router.push('/register-verify');
  }
};

const enums = computed(() => {
  return store.state.global.EnumDetails;
});

/* const categories = computed(() => {
  return store.state.global.jobCategories;
}); */

onBeforeMount(async () => {
  getJobs();
  await store.dispatch('global/getEnums');
  await store.dispatch('global/getJobCategories');
  await store.dispatch('global/getJobCategories');
  console.log(allJobs);
});
</script>
