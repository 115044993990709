<template>
  <section>
    <NavBody>
      <template #body>
        <p class="font-bold text-2xl text-brand-primary">Welcome to your Dashboard, {{ userDetails.name }} 👋</p>
        <p class="font-normal text-base text-[#8C94A6]">Have a beautiful {{ greeting }}!</p>
      </template>
    </NavBody>
    <div
      v-if="pageLoaded"
      class="fixed inset-x-0 top-[225px] mx-auto lg:max-w-[80rem] z-20 lg:h-[725px] h-full gap-6 w-full px-4 lg:px-0 bg-[#FAFAFA]"
    >
      <div class="overflow-scroll h-full scrollbar-hide">
        <div class="bg-white px-6 py-8 rounded-[10px]">
          <div class="">
            <p>Overview</p>
            <p class="font-normal text-base text-[#8C94A6]">Clients need to know more about you to see if you’re a match for their open positions.</p>
          </div>
          <div class="grid md:grid-cols-2 gap-4 my-8">
            <div class="bg-brand-primary rounded-[10px] p-6 h-80 flex flex-col justify-between">
              <div class="mb-6">
                <p class="text-2xl font-albertBold text-white">Current Balance</p>
                <p class="text-sm text-[#ADADAD]">Payout at the end of the month</p>
              </div>
              <div class="flex items-center justify-between">
                <p class="text-4xl text-white font-albertSemiBold">${{ walletBalance }}</p>
                <button class="bg-white text-brand-primary text-center rounded-[10px] px-12 py-5 font-albertSemiBold">Withdraw</button>
              </div>
            </div>
            <div class="border border-[#F0F0F0] rounded-[10px] p-6 h-80">
              <div class="mb-6">
                <p class="text-2xl font-albertBold">Candidate Referral</p>
                <p class="text-sm text-[#666666]">(This Month)</p>
              </div>
              <div>
                <DonutChart :sectionDetail="referralCount" />
              </div>
            </div>
          </div>
          <div class="p-6 border border-[#F0F0F0] rounded-[10px]">
            <div class="mb-6 flex items-center justify-between">
              <div>
                <p class="font-albertSemiBold">Referred Candidates</p>
                <p v-if="referrals.length > 0">Most 4 recent Candidates i referred</p>
              </div>
              <p @click="router.push('/referrals')" class="text-sm font-albertSemiBold underline underline-offset-2 cursor-pointer">View More</p>
            </div>
            <div class="grid md:grid-cols-2 gap-4" v-if="referrals.length > 0">
              <div v-for="x in 4" :key="x">
                <div
                  class="py-4 px-6 bg-white border border-[#F3F4F6] flex md:flex-row flex-col md:items-center md:justify-between w-full rounded-[10px]"
                >
                  <div class="flex items-center gap-x-2">
                    <img src="@/assets/images/girl.png" class="rounded-full w-10 h-10" alt="" />
                    <span>
                      <p class="text-2xl text-brand-primary">Saviour Inyang</p>
                      <p class="text-[#4351D2] text-sm font-albertSemiBold">Brand Designer</p>
                    </span>
                  </div>
                  <div class="border h-full md:hidden my-2"></div>
                  <div class="gap-1">
                    <p class="rounded-[52px] text-[#666666] bg-[#FAFAFA] w-full">Applied</p>
                    <p class="text-brand-primary text-sm">Referred on 31st January, 2024</p>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="mx-auto text-center">
              <div class="flex -space-x-4 rtl:space-x-reverse justify-center">
                <img v-for="i in 7" :key="i" class="w-10 h-10 border-2 border-white rounded-full" src="@/assets/images/girl.png" alt="" />
              </div>
              <p class="font-albertSemiBold text-lg text-brand-primary">
                Sorry, you have not referred <br />
                anyone at the moment
              </p>
              <p class="text-sm text-[#485467]">Click on a job and refer a candidate</p>
            </div>
          </div>
        </div>
        <div class="my-6 grid md:grid-cols-2 gap-6">
          <div class="px-6 py-8 bg-white rounded-[10px] border border-[#F3F4F6] flex flex-col justify-between">
            <div class="mb-6">
              <p class="text-2xl font-albert text-brand-primary">Active Referrals</p>
              <p class="text-base text-[#8C94A6]">Here is a list of everyone you have referred on the platform</p>
            </div>
            <div v-if="referralCount.all > 0" class="bg-[#FEFAF1] py-8 px-5 flex items-center justify-between rounded-[20px]">
              <div>
                <p>You have referred {{ referralCount.all }} people</p>
                <div class="flex -space-x-4 rtl:space-x-reverse">
                  <img
                    v-for="i in referralCount.all"
                    :key="i"
                    class="w-10 h-10 border-2 border-white rounded-full"
                    src="@/assets/images/whiteAva.svg"
                    alt=""
                  />
                  <!-- <img class="w-10 h-10 border-2 border-white rounded-full" src="@/assets/images/girlAva.svg" alt="" />
                  <img class="w-10 h-10 border-2 border-white rounded-full" src="@/assets/images/Avatar.svg" alt="" />
                  <img class="w-10 h-10 border-2 border-white rounded-full" src="@/assets/images/girlAva.svg" alt="" /> -->
                  <a
                    v-if="referralCount.all > 9"
                    class="flex items-center justify-center w-10 h-10 text-xs font-medium text-white bg-gray-200 border-2 border-white rounded-full"
                    href="#"
                    >+{{ referralCount.all - 9 }}</a
                  >
                </div>
              </div>
              <p class="text-brand-primary underline font-albertSemiBold cursor-pointer" @click="router.push('/referrals')">View all</p>
            </div>
            <div v-else class="bg-[#FEFAF1] py-8 px-5 flex items-center justify-between rounded-[20px]">
              <div>
                <div class="flex -space-x-4 rtl:space-x-reverse">
                  <img class="w-10 h-10 rounded-full" src="@/assets/images/whiteAva.svg" alt="" />
                  <img class="w-10 h-10 rounded-full" src="@/assets/images/girlAva.svg" alt="" />
                  <img class="w-10 h-10 rounded-full" src="@/assets/images/Avatar.svg" alt="" />
                  <img class="w-10 h-10 rounded-full" src="@/assets/images/girlAva.svg" alt="" />
                  <img class="w-10 h-10 rounded-full" src="@/assets/images/whiteAva.svg" alt="" />
                  <img class="w-10 h-10 rounded-full" src="@/assets/images/Avatar.svg" alt="" />
                </div>
              </div>
              <p class="font-albertSemiBold text-sm">Sorry, you have not referred anyone at the moment</p>
            </div>
          </div>
          <div class="px-6 py-8 bg-white rounded-[10px] border border-[#F3F4F6]">
            <p class="mb-8 text-2xl font-albertSemiBold">Refer your network to join CareerBuddy</p>
            <div class="bg-[#FBE9F2] rounded-[20px] pl-6 flex items-center justify-between">
              <div class="py-6 w-[40%]">
                <p class="mb-4 font-albertSemiBold text-brand-primary">
                  Get your own referral link and invite your fellow recruiter to join careerbuddy
                </p>
                <button @click="copyReferral" class="px-3 py-2 bg-brand-primary rounded-[10px] text-white flex items-center gap-x-2">
                  Copy Referral Link <Copy />
                </button>
              </div>
              <div class="relative">
                <img src="@/assets/images/circleBackgrounds.svg" alt="" class="" />
                <img src="@/assets/images/refer.svg" alt="" class="absolute top-2 right-[18px]" />
              </div>
            </div>
          </div>
        </div>
        <div class="rounded-[10px] border border-[#F3F4F6] bg-white py-8 px-6">
          <div class="mb-8 flex justify-between items-center">
            <div>
              <p class="text-brand-primary font-albertSemiBold">Top Job Opportunities</p>
              <p class="text-[#8C94A6]">These companies are hiring roles</p>
            </div>
            <p class="underline underline-offset-4 text-brand-primary font-albertSemiBold text-base cursor-pointer" @click="router.push('/jobs')">
              View all jobs
            </p>
          </div>
          <div
            class="rounded-[10px] border border-[#F3F4F6] bg-white lg:px-4 px-2 py-6 flex flex-col lg:flex-row lg:items-center lg:justify-between mb-4"
            v-for="item in allJobs"
            :key="item"
          >
            <div class="grow">
              <p class="mb-4 mt-3 lg:mt-0 text-brand-primary text-2xl font-albertSemiBold capitalize">{{ item.role_title }}</p>
              <div class="flex lg:block justify-between itemscenter">
                <div class="space-y-4">
                  <span class="flex items-center lg:gap-x-3 gap-x-1">
                    <Coins />
                    <p class="text-brand-primary font-albertSemiBold">
                      <span class="text-sm">{{ item.salary_currency }}</span>
                      {{ formatNumber(item?.salary_min) || item?.salary_min?.formatted }} -
                      {{ formatNumber(item?.salary_max) || item?.salary_max?.formatted }}
                      <!-- <span class="font-normal text-base">/ {{ item.pay_period.split('_').join(' ') }}</span> -->
                    </p>
                  </span>
                  <span class="flex items-center lg:gap-x-3 gap-x-1">
                    <Stairs />
                    <p class="text-sm text-[#1B224BCC] capitalize">{{ item.level_of_experience.split('_').join(' ') }}</p>
                  </span>
                  <span class="flex items-center lg:gap-x-3 gap-x-1">
                    <Location />
                    <p class="text-sm text-[#1B224BCC] capitalize">{{ item.state.name }} | {{ item.work_type.split('_').join(' ') }}</p>
                  </span>
                </div>
                <div class="space-y-4">
                  <span class="lg:hidden items-center lg:gap-x-3 gap-x-1 flex">
                    <People />
                    <p class="text-[#1B224BCC] text-sm">50 employees</p>
                  </span>
                  <span class="lg:hidden items-center lg:gap-x-3 gap-x-1 flex">
                    <Job />
                    <p class="bg-[#089115] px-2.5 py-[6px] rounded-[10px] font-albertSemiBold text-white text-sm">
                      {{ item.employer.company_category }}
                    </p>
                  </span>
                </div>
              </div>
            </div>
            <div class="border-l-2 h-[143px] hidden lg:block"></div>
            <div class="flex items-center justify-between lg:ml-10 grow order-first lg:order-none">
              <div class="lg:space-y-4">
                <img :src="item.employer.logo_url" alt="logo" class="self-start lg:self-auto h-14 rounded object-contain" />
                <span class="lg:flex items-center gap-x-3 hidden">
                  <People />
                  <p class="text-[#1B224BCC] text-sm">50 employees</p>
                </span>
                <span class="lg:flex items-center gap-x-3 hidden">
                  <Job />
                  <p class="bg-[#089115] px-2.5 py-[6px] rounded-[10px] font-albertSemiBold text-white text-sm capitalize">
                    {{ item.employer.company_category || 'N/A' }}
                  </p>
                </span>
              </div>
              <div class="grid grid-rows-2 gap-4">
                <button @click.prevent="viewJob(item)" class="bg-brand-primary text-white rounded-[10px] py-2 lg:py-4 px-8">Apply</button>
                <button @click.prevent="viewJob(item)" class="bg-[#FEFAF1] text-block rounded-[10px] py-2 lg:py-4 px-8 font-albertSemiBold">
                  Refer a friend
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loading :is-loading="!pageLoaded" className="flex justify-center" />
  </section>
</template>

<script setup>
import Search from '@/assets/icons/search.svg?inline';
import Button from '@/components/Button.vue';
import Select from '@/components/form/Select.vue';
import Input from '@/components/form/Input.vue';
import Card from '@/components/jobCard.vue';
import NavBody from '@/components/NavBody.vue';
import Paginate from '@/components/Paginate.vue';
import DonutChart from '@/components/DonutChart.vue';
import { ref, computed, onBeforeMount, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { errorMessage, alert, formatNumber, formatTitle } from '@/utils/helper';
import Loading from '@/components/Loading.vue';
import People from '@/assets/icons/users.svg?inline';
import Coins from '@/assets/icons/coins.svg?inline';
import Job from '@/assets/icons/job.svg?inline';
import Copy from '@/assets/icons/copy.svg?inline';
import Location from '@/assets/icons/location.svg?inline';
import Stairs from '@/assets/icons/stairs.svg?inline';

const store = useStore();
const router = useRouter();
const pageLoaded = ref(false);
const currentPage = ref(1);
const referrals = ref([]);

const filter = ref({
  search: '',
  type: '',
  work_type: '',
});

const JobsData = computed(() => {
  return store.getters['global/getAllJobs'];
});

const allJobs = computed(() => {
  return store.getters['global/getJobs'];
});

const walletBalance = computed(() => {
  return store.getters['global/getWalletBalance'];
});

const referralCount = computed(() => {
  return store.getters['global/getReferralCount'];
});

const viewJob = (job) => {
  console.log(job);
  router.push(`/${formatTitle(job?.employer?.name)}/${job?.fid}/${formatTitle(job?.role_title)}`);
};

const userDetails = computed(() => store.getters['auth/userDetails']);

const getJobs = async () => {
  pageLoaded.value = false;
  try {
    await store.dispatch('global/getAllJobs', { page: currentPage.value, ...filter.value });
    pageLoaded.value = true;
  } catch (error) {
    alert(errorMessage(error), 'error');
    pageLoaded.value = true;
    if (error.response.status === 409) router.push('/register-verify');
  }
};

const enums = computed(() => {
  return store.state.global.EnumDetails;
});

const greeting = computed(() => {
  const currentHour = new Date().getHours();

  if (currentHour < 12) {
    return 'Morning';
  } else if (currentHour < 18) {
    return 'Afternoon';
  } else {
    return 'Evening';
  }
});

const copyReferral = () => {
  navigator.clipboard
    .writeText(
      `https://candidate.thecareerbuddy.com/register?referralId=${userDetails.value.uuid}
`,
    )
    .then(() => {
      alert('Text copied to clipboard!');
    })
    .catch((err) => {
      console.error('Failed to copy text: ', err);
    });
};

/* const categories = computed(() => {
  return store.state.global.jobCategories;
}); */

onBeforeMount(async () => {
  getJobs();
  await store.dispatch('global/getEnums');
  await store.dispatch('global/getJobCategories');
  await store.dispatch('global/walletBalance');
  await store.dispatch('global/referralCount');
  pageLoaded.value = true;
});

onMounted(() => {
  console.log(allJobs, '====================', referralCount);
});
</script>
